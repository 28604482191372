@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300&family=Gaegu&display=swap");
body {
  margin: 0;
  font-family: Rajdhani, "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f7ff;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.HomeApp {
  font-family: Frank Ruhl Libre;
  background: #ffffff;
  height: 100vh;  
}

.EngineeringApp {
  font-family: Rajdhani, "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  background: #ffffff;
  height: 100vh;
}

.GownApp {
  font-family: Frank Ruhl Libre;
}

.WritingApp {
  font-family: Frank Ruhl Libre;
}

:root {
  --button-raise-level: 5px;
  --button-default-height: 200px;
}
